.learn-to-play-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .main-title {
    text-align: center;
    margin-bottom: 2rem;
    color: #333;
  }
  
  .intro-card {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .flip-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .flip-card {
    background-color: transparent;
    width: 100%;
    height: 300px;
    perspective: 1000px;
    cursor: pointer;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .flip-card.flipped .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
  
  .flip-card-front {
    background-color: #3498db;
    color: white;
  }
  
  .flip-card-back {
    background-color: #2ecc71;
    color: white;
    transform: rotateY(180deg);
  }
  
  .flip-card-back .content {
    overflow-y: auto;
    max-height: 100%;
  }
  
  .flip-card h2 {
    font-size: 1.5rem;
    margin-bottom: 0;
  }
  
  .flip-card-back ul {
    text-align: left;
    padding-left: 1.5rem;
  }
  
  .flip-card-back li {
    margin-bottom: 0.5rem;
  }