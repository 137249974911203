/* SVG Animation Styles */
.svg-animation-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 150px; /* Adjust this value based on your SVG's height */
}

.animated-svg {
  position: absolute;
  left: -100%; /* Start off-screen to the left */
  height: 100%;
  animation: slide-right 10s linear infinite; /* Adjust duration as needed */
}

@keyframes slide-right {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

/* Existing styles (unchanged) */
.product-device {
  position: absolute;
  right: 10%;
  bottom: -30%;
  width: 300px;
  height: 540px;
  background-color: #333;
  border-radius: 21px;
  transform: rotate(30deg);
}

.product-device::before {
  position: absolute;
  top: 10%;
  right: 10px;
  bottom: 10%;
  left: 10px;
  content: "";
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

.product-device-2 {
  top: -25%;
  right: auto;
  bottom: 0;
  left: 5%;
  background-color: #e5e5e5;
}

/* You can keep any other existing styles that are still relevant */