.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5;
  }
  
  .login-box {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .login-box h1 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  /* Supabase Auth UI customization */
  .login-box :global(.supabase-auth-ui_ui-container) {
    max-width: 100%;
  }
  
  .login-box :global(.supabase-auth-ui_ui-button) {
    width: 100%;
  }
  
  .login-box :global(.supabase-auth-ui_ui-divider) {
    margin: 1.5rem 0;
  }
  
  .login-box :global(.supabase-auth-ui_ui-input) {
    margin-bottom: 1rem;
  }
  
  /* Dark theme adjustments */
  .login-box :global(.supabase-auth-ui_ui-container[data-theme="dark"]) {
    background-color: #1e1e1e;
    color: #ffffff;
  }
  
  .login-box :global(.supabase-auth-ui_ui-container[data-theme="dark"] .supabase-auth-ui_ui-input) {
    background-color: #333;
    color: #ffffff;
  }
  
  .login-box :global(.supabase-auth-ui_ui-container[data-theme="dark"] .supabase-auth-ui_ui-button) {
    background-color: #4a4a4a;
    color: #ffffff;
  }